<template>
  <div>
    <v-page-title title="Reports Settings">

    </v-page-title>

    <v-card max-width="1200px" elevation="0" color="transparent" class="mx-auto my-5">
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-report-branding></v-report-branding>
          </v-col>
          <v-col cols="12" md="6">
            <v-footer-disclaimer></v-footer-disclaimer>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'Reports.vue',
    data() {
      return {
      }
    },
    computed:{

    },
  }
</script>

<style lang="scss" scoped>
</style>